<template lang="pug">
  div
    base-snackbar(
      :errors.sync="unitStorePostErrors"
    )
    v-row
      v-col(cols="12").mt-5
        v-text-field(
          label="Unit"
          v-model="id"
          dense
          :error-messages="unitStorePostErrors.id"
        )
      v-col(cols="12").my-0.py-0
        v-text-field(
          label="Description"
          v-model="description"
          dense
          :error-messages="unitStorePostErrors.description"
        )
      v-col(cols="12")
        v-btn(
          color="green"
          block
          small
          dark
          dense
          @click="store"
          :loading="unitStorePosting"
        )
          span Save
</template>
<script>
import unitRepository from '@/repositories/unit.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [unitStoreVars, unitStoreVarNames] = requestVars({ identifier: 'unit-store', request: 'post', hasData: false })
const unitStoreHandler = new VueRequestHandler(null, unitStoreVarNames)

const inputVars = () => ({
  id: null,
  description: null,
})

export default {
  name: 'CreateUnit',
  data () {
    return {
      ...unitStoreVars,
      ...inputVars(),
    }
  },
  methods: {
    store () {
      const handler = unitStoreHandler
      const repository = unitRepository.store
      const defaults = inputVars()
      const data = this.$getInputData(defaults)
      handler.setVue(this)
      handler.execute(repository, [data], () => { this.$resetInputData(defaults) })
    },
  },
}
</script>